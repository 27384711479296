import React from 'react'

export const Button = ({ onClick, text, className, disabled, type }) => {
  return (
    <button 
      type={type}
      className={`h-[34px] w-[34px] bg-dark-blue hover:bg-blue-hover text-[13px] font-lexend duration-300 text-white rounded-[50%] flex justify-center items-center ${className}`}
      onClick={onClick} 
      disabled={disabled}
    >
      {text}
    </button>
  )
}