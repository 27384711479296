// import { useEffect, useState } from 'react';
import './App.css';
import { Navbar, Home, Merk, Contact, Footer, Product } from './pages';

function App() {

  return (
    <div className="App">
      <div className='mx-4 lg:mx-8'>
        <Navbar />
        <section id="home">
          <Home />
        </section>
        <section id="product">
          <Product />
        </section>
        <section id="merk">
          <Merk />
        </section>
        <section id="contact">
          <Contact />
        </section>
        <Footer />
      </div>
    </div>
  );
}

export default App;
