import React from 'react'
import { TextLabel } from '../../components'
import { data } from "../../datas"

export const Merk = () => {
  return (
    <section className='mt-10'>
      <TextLabel text="Trade Cabel Merk" />
      <img src={data?.assets?.cabel_merk} alt='Logo Cabels' className='mx-auto w-[280px] h-[62px] lg:w-[889px] lg:h-[198px]' />
    </section>
  )
}