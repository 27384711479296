import React, { useEffect, useRef, useState } from 'react';
import { getDocument, GlobalWorkerOptions } from 'pdfjs-dist/build/pdf';

GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${window.pdfjsLib?.version || '2.11.338'}/pdf.worker.min.js`;

export const PDFViewers = ({ data }) => {
  const [pdfDocument, setPdfDocument] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true); // State tambahan untuk loading
  const canvasRefs = useRef([]);

  useEffect(() => {
    const loadPdf = async () => {
      try {
        setIsLoading(true); // Set loading ke true saat mulai memuat PDF
        const loadingTask = getDocument(data);
        const pdf = await loadingTask.promise;
        setPdfDocument(pdf);
        setTotalPages(pdf.numPages);
      } catch (error) {
        console.error('Error loading PDF: ', error);
      } finally {
        setIsLoading(false); // Set loading ke false setelah selesai
      }
    };

    loadPdf();
  }, [data]);

  useEffect(() => {
    if (pdfDocument) {
      const renderAllPages = async () => {
        for (let i = 1; i <= totalPages; i++) {
          try {
            const page = await pdfDocument.getPage(i);

            const scale = 1;
            const rotation = page.rotate;

            const viewport = page.getViewport({ scale, rotation });
            const canvas = canvasRefs.current[i - 1];
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;

            context.save();
            if (rotation !== 0) {
              context.translate(viewport.width / 2, viewport.height / 2);
              context.rotate((rotation * Math.PI) / 180);
              context.translate(-viewport.width / 2, -viewport.height / 2);
            }

            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };

            await page.render(renderContext).promise;
            context.restore();
          } catch (error) {
            console.error(`Error rendering page ${i}: `, error);
          }
        }
      };

      renderAllPages();
    }
  }, [pdfDocument, totalPages]);

  return (
    <section className='m-[-40px] lg:p-10'>
      {isLoading ? (
        <p className='text-center'>Loading...</p>
      ) : (
        Array.from({ length: totalPages }).map((_, index) => (
          <canvas
            key={index}
            className='w-full h-auto mb-8'
            ref={(el) => (canvasRefs.current[index] = el)}
          />
        ))
      )}
    </section>
  );
};
