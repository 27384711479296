import React from 'react'
import { Card, TextLabel } from '../../components'
import { data } from "../../datas"
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export const Home = () => {
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000
  };

  return (
    <section className='mt-[60px] lg:mt-[80px]'>
      <Card>
        <section className='lg:flex lg:justify-between lg:py-8 pb-3 pt-8 lg:pr-8 lg:pl-[7.5%] lg:items-center px-2 lg:px-6'>
          <section className='text-center lg:text-left lg:mt-0 mb-5'>
            <TextLabel className="!text-[32px] lg:!text-[64px] lg:mb-[-12px]" text="Pt. Aditya" />
            <TextLabel className="!text-[32px] lg:!text-[64px] lg:!max-w-[600px] mt-[-7px] lg:mt-0 leading-9 lg:leading-[70px]" text="Badratama Internusa" />
            <section className='lg:max-w-[500px] mt-2 lg:mt-5'>
              <p className='font-antonio opacity-[60%] text-[12px] lg:text-[16px] lg:mr-5'>{data?.personal?.description}</p>
            </section>
          </section>
          <section className="h-full lg:h-[446px] w-full lg:w-full lg:max-w-[41vw]">
            {data?.assets?.office?.length > 0 && (
              <Slider {...settings}>
                {data.assets.office.map((item, index) => (
                  <section key={index} className='ml-2 pr-5 rounded-[25px] lg:rounded-[40px]'>
                    <img src={item.images} alt={`Office ${index + 1}`} className="h-[246px] lg:h-[446px] w-full lg:w-[100%] rounded-[25px] lg:rounded-[40px]" />
                  </section>
                ))}
              </Slider>
            )}
          </section>
        </section>
      </Card>
    </section>
  )
}