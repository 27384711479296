import CompanyLogo from "../assets/logo.png"
import OfficeImageTwo from "../assets/office-two.jpeg"
import OfficeImageTree from "../assets/office-tree.jpeg"
import Picture1 from "../assets/1.png"
import Picture2 from "../assets/2.png"
import Picture3 from "../assets/3.png"
import Picture4 from "../assets/4.png"
import Picture5 from "../assets/5.png"
import Picture6 from "../assets/6.png"
import Picture8 from "../assets/8.png"
import Picture9 from "../assets/9.png"
import Picture11 from "../assets/11.png"
import Picture12 from "../assets/12.png"
import CableLogo from "../assets/cables-logo.png"
import BankLogo from "../assets/banks.png"
import DataNYA from "../assets/pdf/NYA.pdf"
import DataNYY from "../assets/pdf/NYY.pdf"
import DataNYM from "../assets/pdf/NYM.pdf"
import DataNYRGbY from "../assets/pdf/NYRGbY.pdf"
import DataNYFGbY from "../assets/pdf/NYFGbY.pdf"
import DataN2XSYNA2XSY from "../assets/pdf/N2XSY-NA2XSY.pdf"
import DataN2XSRYNA2XSRY from "../assets/pdf/N2XSRY-NA2XSRY.pdf"
import DataN2XSEYNA2XSEY from "../assets/pdf/N2XSEY-NA2XSEY.pdf"
import DataN2XSEFGbYNA2XSEFGbY from "../assets/pdf/N2XSEFGbY-NA2XSEFGbY.pdf"
import { PDFViewers } from "../components"

export const data = {
  personal: { 
    logo: CompanyLogo,
    name: "PT.ADITYA BADRATAMA INTERNUSA",
    description: "PT. ADITYA BADRATAMA INTERNUSA was established on October 8, 2003, engaged in the field of Distributor and supplier of electrical cables of the brands KABELINDO, SUPREME, KABELMETAL AND JEMBO, located at JL. RAYA JATIMAKMUR KEMANG NO. 50 Pondok Gede, Bekasi, 17413",
    open: "Senin-Jumat : 8:00 AM To 5:00 PM",
    detail_phone: [
      { phone: "(021) 84977752" },
      { phone: "(021) 84994780" },
    ],
    fax: "(021) 84977634",
    hp: "0811996233",
    email: "aditya_bi@gmail.com",
    address: "RUKO GREEN VILLA BLOK 1A",
    street: "Jl. Raya Jatimakmur Kemang No. 50 Pondok Gede - Bekasi 17413",
    full_address: "RUKO GREEN VILLA BLOK 1A Jl. Raya Jatimakmur Kemang No. 50 Pondok Gede - Bekasi 17413",
    footer: "Copyright © 2024 All Rights Reserved - adityabadratamainternusa.com",
    product: [
        { label: "Cu-PVC (NYA)", detail: () => <PDFViewers data={DataNYA} /> },
        { label: "Cu-PVC-PVC (NYY)", detail: () => <PDFViewers data={DataNYY} /> },
        { label: "Cu-PVC-PVC (NYM)", detail: () => <PDFViewers data={DataNYM} /> },
        { label: "Cu-PVC-SWA-PVC (NYRGbY)", detail: () => <PDFViewers data={DataNYRGbY} /> },
        { label: "Cu-PVC-SFA-PVC (NYFGbY)", detail: () => <PDFViewers data={DataNYFGbY} /> },
        { label: "Cu-XLPE-CTS-PVC-Al-XLPE-CTS-PVC(N2XSY - NA2XSY)", detail: () => <PDFViewers data={DataN2XSYNA2XSY} /> },
        { label: "Cu-XLPE-CTS-AWA-PVC - Al-XLPE-CTS-AWA-PVC (N2XSRY - NA2XSRY)", detail: () => <PDFViewers data={DataN2XSRYNA2XSRY} /> },
        { label: "Cu-XLPE-CTS-PVC - Al-XLPE-CTS-PVC (N2XSEY - NA2XSEY)", detail: () => <PDFViewers data={DataN2XSEYNA2XSEY} /> },
        { label: "Cu-XLPE-CTS-PVC-SFA-PVC - Al-XLPE-CTS-PVC-SFA-PVC (N2XSEFGbY- NA2XSEFGbY)", detail: () => <PDFViewers data={DataN2XSEFGbYNA2XSEFGbY} /> },
    ]
  },
  assets: {
    office: [
      { images: OfficeImageTwo },
      { images: OfficeImageTree },
      { images: Picture1 },
      { images: Picture2 },
      { images: Picture3 },
      { images: Picture4 },
      { images: Picture5 },
      { images: Picture6 },
      { images: Picture8 },
      { images: Picture9 },
      { images: Picture11 },
      { images: Picture12 },
    ],
    cabel_merk: CableLogo,
    bank: BankLogo
  }
}