import React from 'react'
import { Button, TextLabel } from '../../components'
import { MdEmail } from "react-icons/md";
import { FaPhoneAlt } from "react-icons/fa";
import { data } from "../../datas"
import { Link } from 'react-scroll';

export const Navbar = () => {
  const onPhoneSubmit = () => {
    const name = "Test Name"
    const email = "Test Email"
    const message = "Test Message"
    const phoneNumber = '081933929274';

    const messageText = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;
    const encodedMessage = encodeURIComponent(messageText);
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  
    window.location.href = url;
  };
  const onEmailSubmit = () => {
    const email = 'aditya_bi@gmail.com'
    const subject = 'Inquiry about Product Availability'
    const body = `
      Dear Support Team,

      I hope this email finds you well. I am writing to inquire about the availability of your products. Could you please provide more details about the stock and pricing for the following items:

      - Product 1: [Details]
      - Product 2: [Details]

      Additionally, I would appreciate if you could share any ongoing promotions or discounts that might be applicable.

      Thank you for your assistance. I look forward to your response.

      Best regards,
      [Your Name]
    `
  
    const mailtoUrl = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
  
    window.location.href = mailtoUrl;
  }
  const onHotlineSubmit = () => window.location.href = 'tel:0811996233'

  return (
    <nav className='flex justify-between fixed top-0 w-full lg:w-[97%] z-[10000] bg-white ml-[-15px] lg:ml-[-10px]'>
      <section className='p-3 flex gap-2 items-center'>
        <img src={data?.personal?.logo} alt={data?.personal?.name} className='h-[33px] w-[33px] lg:h-[50px] lg:w-[50px]' />
        <TextLabel className="!text-[18px] lg:!text-[23.5px]" text={data?.personal?.name} />
      </section>
      <section className='hidden lg:flex p-3 gap-4 text-dark-blue items-center font-antonio'>
        <Link
          to="home"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="cursor-pointer"
          activeClass="border-b-2 border-blue-500"
        >
          Home
        </Link>
        <Link
          to="product"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="cursor-pointer"
          activeClass="border-b-2 border-blue-500"
        >
          Product
        </Link>
        <Link
          to="merk"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="cursor-pointer"
          activeClass="border-b-2 border-blue-500"
        >
          Trade Cabel Merk
        </Link>
        <Link
          to="contact"
          spy={true}
          smooth={true}
          offset={-70}
          duration={500}
          className="cursor-pointer"
          activeClass="border-b-2 border-blue-500"
        >
          Contact Us
        </Link>
      </section>
      <section className='hidden lg:flex p-3 gap-2 items-center'>
        <Button className="!w-[190px] uppercase !rounded-[20px] font-lexend" text={`Hotline: ${data?.personal?.hp}`} onClick={onHotlineSubmit} />
        <Button text={<FaPhoneAlt />} onClick={onPhoneSubmit} />
        <Button text={<MdEmail />} onClick={onEmailSubmit} />
      </section>
    </nav>
  )
}