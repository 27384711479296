import React from 'react'
import { LabelCard } from '../card';
import CableOne from "../../assets/Cu-PVC-(NYA).png"
import CableTwo from "../../assets/750-V-(NYAF).png"
import Colour from "../../assets/colour.png"
import ColourTwo from "../../assets/colour-two.png"
import { Table, Th, Tr } from '../tables';

const detailProduct = {
    dataTableNYA: {
        NYA: [
            { sqmm: "1.5 re", mm_nominal: "0.7", mm_overall: "3.3", kg: "19", mohm: "50", tipe_a: "15", air_a: "24", kA: "0.17", mm_standard: "100/C" },
            { sqmm: "1.5 rm", mm_nominal: "0.7", mm_overall: "3.4", kg: "20", mohm: "50", tipe_a: "15", air_a: "24", kA: "0.17", mm_standard: "100/C" },
            { sqmm: "2.5 re", mm_nominal: "0.8", mm_overall: "3.9", kg: "31", mohm: "50", tipe_a: "19", air_a: "32", kA: "0.29", mm_standard: "100/C" },
            { sqmm: "2.5 rm", mm_nominal: "0.8", mm_overall: "4.2", kg: "33", mohm: "50", tipe_a: "19", air_a: "32", kA: "0.29", mm_standard: "100/C" },
            { sqmm: "4 re", mm_nominal: "0.8", mm_overall: "4.4", kg: "45", mohm: "50", tipe_a: "25", air_a: "42", kA: "0.46", mm_standard: "100/C" },
            { sqmm: "4 rm", mm_nominal: "0.8", mm_overall: "4.7", kg: "48", mohm: "50", tipe_a: "25", air_a: "42", kA: "0.46", mm_standard: "100/C" },
            { sqmm: "6 re", mm_nominal: "0.8", mm_overall: "4.9", kg: "65", mohm: "50", tipe_a: "33", air_a: "54", kA: "0.69", mm_standard: "100/C" },
            { sqmm: "6 rm", mm_nominal: "0.8", mm_overall: "5.4", kg: "71", mohm: "50", tipe_a: "33", air_a: "54", kA: "0.69", mm_standard: "100/C" },
            { sqmm: "10 re", mm_nominal: "1.0", mm_overall: "6.1", kg: "92", mohm: "40", tipe_a: "45", air_a: "73", kA: "1.15", mm_standard: "100/C" },
            { sqmm: "10 rm", mm_nominal: "1.0", mm_overall: "6.8", kg: "108", mohm: "40", tipe_a: "45", air_a: "73", kA: "1.15", mm_standard: "100/C" },
            { sqmm: "16 rm", mm_nominal: "1.0", mm_overall: "8.0", kg: "175", mohm: "40", tipe_a: "61", air_a: "98", kA: "1.84", mm_standard: "3000/D" },
            { sqmm: "25 rm", mm_nominal: "1.2", mm_overall: "9.8", kg: "273", mohm: "40", tipe_a: "83", air_a: "129", kA: "2.88", mm_standard: "3000/D" },
            { sqmm: "35 rm", mm_nominal: "1.2", mm_overall: "11", kg: "367", mohm: "40", tipe_a: "103", air_a: "158", kA: "4.03", mm_standard: "2000/D" },
            { sqmm: "50 rm", mm_nominal: "1.4", mm_overall: "13", kg: "523", mohm: "30", tipe_a: "132", air_a: "197", kA: "5.75", mm_standard: "1000/D" },
            { sqmm: "70 rm", mm_nominal: "1.4", mm_overall: "15", kg: "692", mohm: "30", tipe_a: "165", air_a: "245", kA: "8.05", mm_standard: "1000/D" },
            { sqmm: "95 rm", mm_nominal: "1.6", mm_overall: "17", kg: "962", mohm: "30", tipe_a: "197", air_a: "290", kA: "10.93", mm_standard: "1000/D" },
            { sqmm: "120 rm", mm_nominal: "1.6", mm_overall: "19", kg: "1192", mohm: "30", tipe_a: "235", air_a: "345", kA: "13.80", mm_standard: "500/D" },
            { sqmm: "150 rm", mm_nominal: "1.8", mm_overall: "21", kg: "1511", mohm: "20", tipe_a: "-", air_a: "390", kA: "17.25", mm_standard: "500/D" },
            { sqmm: "185 rm", mm_nominal: "2.0", mm_overall: "23.5", kg: "1844", mohm: "20", tipe_a: "-", air_a: "445", kA: "21.28", mm_standard: "500/D" },
            { sqmm: "240 rm", mm_nominal: "2.2", mm_overall: "26.5", kg: "2430", mohm: "20", tipe_a: "-", air_a: "525", kA: "27.60", mm_standard: "500/D" },
            { sqmm: "300 rm", mm_nominal: "2.4", mm_overall: "29.5", kg: "3015", mohm: "20", tipe_a: "-", air_a: "605", kA: "34.50", mm_standard: "500/D" },
            { sqmm: "400 rm", mm_nominal: "2.6", mm_overall: "33.5", kg: "3863", mohm: "20", tipe_a: "-", air_a: "725", kA: "46.00", mm_standard: "500/D" },
        ],
        NYAF: [
            { sqmm: "0.5 f", mm_wire: "16/0.2", mm_nominal: "0.6", mm_overall: "2.6", kg: "10", mohmkm: "50", pipe_a: "2.5", air_a: "-", kA: "0.06", mm_standard: "100/C" },
            { sqmm: "0.75 f", mm_wire: "24/0.2", mm_nominal: "0.6", mm_overall: "2.8", kg: "13", mohmkm: "50", pipe_a: "7", air_a: "-", kA: "0.09", mm_standard: "100/C" },
            { sqmm: "1.0 f", mm_wire: "32/0.2", mm_nominal: "0.6", mm_overall: "3", kg: "16", mohmkm: "50", pipe_a: "11", air_a: "19", kA: "0.12", mm_standard: "100/C" },
            { sqmm: "1.5 f", mm_wire: "30/0.25", mm_nominal: "0.7", mm_overall: "3.5", kg: "23", mohmkm: "50", pipe_a: "15", air_a: "24", kA: "0.17", mm_standard: "100/C" },
            { sqmm: "2.5 f", mm_wire: "50/0.25", mm_nominal: "0.8", mm_overall: "4.2", kg: "36", mohmkm: "50", pipe_a: "20", air_a: "32", kA: "0.29", mm_standard: "100/C" },
            { sqmm: "4 f", mm_wire: "56/0.3", mm_nominal: "0.8", mm_overall: "5", kg: "51", mohmkm: "50", pipe_a: "25", air_a: "41", kA: "0.46", mm_standard: "100/C" },
            { sqmm: "6 f", mm_wire: "84/0.3", mm_nominal: "0.8", mm_overall: "6.3", kg: "73", mohmkm: "50", pipe_a: "33", air_a: "53", kA: "0.69", mm_standard: "100/C" },
            { sqmm: "10 f", mm_wire: "84/0.4", mm_nominal: "1.0", mm_overall: "7.4", kg: "116", mohmkm: "40", pipe_a: "45", air_a: "72", kA: "1.15", mm_standard: "100/C" },
            { sqmm: "16 f", mm_wire: "126/0.4", mm_nominal: "1.0", mm_overall: "8.6", kg: "180", mohmkm: "40", pipe_a: "61", air_a: "97", kA: "1.84", mm_standard: "100/C" },
            { sqmm: "25 f", mm_wire: "196/0.4", mm_nominal: "1.2", mm_overall: "10.8", kg: "275", mohmkm: "40", pipe_a: "82", air_a: "128", kA: "2.88", mm_standard: "500/C" },
            { sqmm: "35 f", mm_wire: "278/0.4", mm_nominal: "1.2", mm_overall: "12", kg: "385", mohmkm: "40", pipe_a: "102", air_a: "156", kA: "4.03", mm_standard: "500/C" },
            { sqmm: "50 f", mm_wire: "398/0.4", mm_nominal: "1.4", mm_overall: "14.5", kg: "550", mohmkm: "30", pipe_a: "131", air_a: "195", kA: "5.75", mm_standard: "500/C" },
            { sqmm: "70 f", mm_wire: "360/0.5", mm_nominal: "1.4", mm_overall: "16.5", kg: "750", mohmkm: "30", pipe_a: "164", air_a: "243", kA: "8.05", mm_standard: "500/C" },
            { sqmm: "95 f", mm_wire: "475/0.5", mm_nominal: "1.6", mm_overall: "19.5", kg: "5000", mohmkm: "30", pipe_a: "205", air_a: "287", kA: "10.93", mm_standard: "500/C" },
            { sqmm: "120 f", mm_wire: "608/0.5", mm_nominal: "1.6", mm_overall: "21", kg: "1240", mohmkm: "20", pipe_a: "233", air_a: "342", kA: "13.80", mm_standard: "500/C" },
            { sqmm: "150 f", mm_wire: "760/0.5", mm_nominal: "1.8", mm_overall: "23.5", kg: "1550", mohmkm: "20", pipe_a: "-", air_a: "386", kA: "17.25", mm_standard: "500/C" },
            { sqmm: "185 f", mm_wire: "925/0.5", mm_nominal: "2", mm_overall: "26", kg: "1900", mohmkm: "20", pipe_a: "-", air_a: "441", kA: "21.28", mm_standard: "500/C" },
            { sqmm: "240 f", mm_wire: "1221/0.5", mm_nominal: "2.2", mm_overall: "29.5", kg: "2450", mohmkm: "20", pipe_a: "-", air_a: "525", kA: "27.60", mm_standard: "500/C" },
            { sqmm: "300 f", mm_wire: "1517/0.5", mm_nominal: "2.4", mm_overall: "32.5", kg: "3025", mohmkm: "20", pipe_a: "-", air_a: "605", kA: "34.50", mm_standard: "500/C" },
        ]
    },
    dataTableNYY: {
        NYY: [
            { sq_mm: "1 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.4", outer_diameter_mm: "5.8", weight_kg_per_km: "50", resistance_MOhm_km: "50", a1: "21", a2: "27", a3: "27", a4: "27", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "1 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.4", outer_diameter_mm: "6", weight_kg_per_km: "52", resistance_MOhm_km: "50", a1: "21", a2: "24", a3: "27", a4: "27", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "1 x 2.5 re", diameter_mm: "0.8", insulation_mm: "1.4", outer_diameter_mm: "6.2", weight_kg_per_km: "63", resistance_MOhm_km: "50", a1: "28", a2: "27", a3: "35", a4: "35", short_circuit_current_kA: "0.29", length_m: "1000/D" },
            { sq_mm: "1 x 2.5 rm", diameter_mm: "0.8", insulation_mm: "1.4", outer_diameter_mm: "6.5", weight_kg_per_km: "67", resistance_MOhm_km: "50", a1: "28", a2: "35", a3: "35", a4: "35", short_circuit_current_kA: "0.29", length_m: "1000/D" },
            { sq_mm: "1 x 4 re", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "7.1", weight_kg_per_km: "87", resistance_MOhm_km: "50", a1: "38", a2: "37", a3: "45", a4: "46", short_circuit_current_kA: "0.46", length_m: "1000/D" },
            { sq_mm: "1 x 4 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "7.4", weight_kg_per_km: "92", resistance_MOhm_km: "50", a1: "38", a2: "37", a3: "45", a4: "46", short_circuit_current_kA: "0.46", length_m: "1000/D" },
            { sq_mm: "1 x 6 re", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "8", weight_kg_per_km: "110", resistance_MOhm_km: "50", a1: "48", a2: "46", a3: "57", a4: "57", short_circuit_current_kA: "0.69", length_m: "1000/D" },
            { sq_mm: "1 x 6 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "8.6", weight_kg_per_km: "117", resistance_MOhm_km: "50", a1: "48", a2: "46", a3: "57", a4: "57", short_circuit_current_kA: "0.69", length_m: "1000/D" },
            { sq_mm: "1 x 10 re", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "8.5", weight_kg_per_km: "157", resistance_MOhm_km: "50", a1: "65", a2: "64", a3: "76", a4: "76", short_circuit_current_kA: "1.15", length_m: "1000/D" },
            { sq_mm: "1 x 10 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "8.8", weight_kg_per_km: "163", resistance_MOhm_km: "50", a1: "65", a2: "64", a3: "76", a4: "76", short_circuit_current_kA: "1.15", length_m: "1000/D" },
            { sq_mm: "1 x 16 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "10.4", weight_kg_per_km: "227", resistance_MOhm_km: "40", a1: "84", a2: "84", a3: "97", a4: "98", short_circuit_current_kA: "1.84", length_m: "1000/D" },
            { sq_mm: "1 x 25 rm", diameter_mm: "1.0", insulation_mm: "1.4", outer_diameter_mm: "11.6", weight_kg_per_km: "336", resistance_MOhm_km: "40", a1: "117", a2: "114", a3: "117", a4: "114", short_circuit_current_kA: "2.88", length_m: "1000/D" },
            { sq_mm: "1 x 35 rm", diameter_mm: "1.2", insulation_mm: "1.4", outer_diameter_mm: "12.7", weight_kg_per_km: "437", resistance_MOhm_km: "40", a1: "144", a2: "140", a3: "150", a4: "140", short_circuit_current_kA: "4.03", length_m: "1000/D" },
            { sq_mm: "1 x 50 rm", diameter_mm: "1.4", insulation_mm: "1.4", outer_diameter_mm: "14.3", weight_kg_per_km: "562", resistance_MOhm_km: "30", a1: "177", a2: "172", a3: "180", a4: "172", short_circuit_current_kA: "5.75", length_m: "1000/D" },
            { sq_mm: "1 x 70 rm", diameter_mm: "1.4", insulation_mm: "1.4", outer_diameter_mm: "16", weight_kg_per_km: "768", resistance_MOhm_km: "30", a1: "225", a2: "218", a3: "218", a4: "218", short_circuit_current_kA: "8.05", length_m: "1000/D" },
            { sq_mm: "1 x 95 rm", diameter_mm: "1.6", insulation_mm: "1.5", outer_diameter_mm: "18.5", weight_kg_per_km: "1049", resistance_MOhm_km: "30", a1: "278", a2: "270", a3: "260", a4: "270", short_circuit_current_kA: "10.93", length_m: "1000/D" },
            { sq_mm: "1 x 120 rm", diameter_mm: "1.6", insulation_mm: "1.5", outer_diameter_mm: "20", weight_kg_per_km: "1280", resistance_MOhm_km: "20", a1: "325", a2: "315", a3: "296", a4: "300", short_circuit_current_kA: "13.8", length_m: "1000/D" },
            { sq_mm: "1 x 150 rm", diameter_mm: "1.8", insulation_mm: "1.6", outer_diameter_mm: "22.5", weight_kg_per_km: "1597", resistance_MOhm_km: "20", a1: "373", a2: "362", a3: "331", a4: "336", short_circuit_current_kA: "17.25", length_m: "1000/D" },
            { sq_mm: "1 x 185 rm", diameter_mm: "2.0", insulation_mm: "1.7", outer_diameter_mm: "24.5", weight_kg_per_km: "1959", resistance_MOhm_km: "20", a1: "433", a2: "420", a3: "374", a4: "379", short_circuit_current_kA: "21.28", length_m: "1000/D" },
            { sq_mm: "1 x 240 rm", diameter_mm: "2.2", insulation_mm: "1.8", outer_diameter_mm: "28", weight_kg_per_km: "2538", resistance_MOhm_km: "20", a1: "518", a2: "503", a3: "432", a4: "439", short_circuit_current_kA: "27.6", length_m: "1000/D" },
            { sq_mm: "1 x 300 rm", diameter_mm: "2.4", insulation_mm: "1.9", outer_diameter_mm: "31", weight_kg_per_km: "3157", resistance_MOhm_km: "20", a1: "598", a2: "580", a3: "486", a4: "494", short_circuit_current_kA: "34.5", length_m: "1000/D" },
            { sq_mm: "1 x 400 rm", diameter_mm: "2.6", insulation_mm: "2.0", outer_diameter_mm: "34", weight_kg_per_km: "3995", resistance_MOhm_km: "20", a1: "695", a2: "674", a3: "549", a4: "558", short_circuit_current_kA: "46", length_m: "1000/D" },
            { sq_mm: "1 x 500 rm", diameter_mm: "2.8", insulation_mm: "2.1", outer_diameter_mm: "38", weight_kg_per_km: "5075", resistance_MOhm_km: "20", a1: "806", a2: "781", a3: "618", a4: "629", short_circuit_current_kA: "57.5", length_m: "1000/D" },
            { sq_mm: "1 x 630 rm", diameter_mm: "2.8", insulation_mm: "2.3", outer_diameter_mm: "43", weight_kg_per_km: "6449", resistance_MOhm_km: "20", a1: "930", a2: "901", a3: "692", a4: "704", short_circuit_current_kA: "72.45", length_m: "500/D" }
        ],
        NYY2: [
            { sq_mm: "7 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "14.5", weight_kg_per_km: "324", resistance_MOhm_km: "50", a1: "21", a2: "27", a3: "17", a4: "13", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "7 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "15", weight_kg_per_km: "341", resistance_MOhm_km: "50", a1: "21", a2: "24", a3: "17", a4: "13", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "8 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "15.5", weight_kg_per_km: "362", resistance_MOhm_km: "50", a1: "28", a2: "27", a3: "16", a4: "12", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "8 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "16", weight_kg_per_km: "381", resistance_MOhm_km: "50", a1: "28", a2: "35", a3: "16", a4: "12", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "10 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "17.4", weight_kg_per_km: "434", resistance_MOhm_km: "50", a1: "38", a2: "37", a3: "15", a4: "12", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "10 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "18.1", weight_kg_per_km: "458", resistance_MOhm_km: "50", a1: "38", a2: "37", a3: "15", a4: "12", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "12 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "17.9", weight_kg_per_km: "480", resistance_MOhm_km: "50", a1: "48", a2: "46", a3: "14", a4: "11", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "12 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "18.6", weight_kg_per_km: "506", resistance_MOhm_km: "50", a1: "48", a2: "46", a3: "14", a4: "11", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "14 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "18.7", weight_kg_per_km: "542", resistance_MOhm_km: "50", a1: "65", a2: "64", a3: "13", a4: "11", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "14 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "19.5", weight_kg_per_km: "572", resistance_MOhm_km: "50", a1: "65", a2: "64", a3: "13", a4: "11", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "16 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "19.5", weight_kg_per_km: "599", resistance_MOhm_km: "50", a1: "84", a2: "84", a3: "12", a4: "10", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "16 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "20.3", weight_kg_per_km: "632", resistance_MOhm_km: "50", a1: "117", a2: "114", a3: "12", a4: "10", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "19 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "20.4", weight_kg_per_km: "670", resistance_MOhm_km: "50", a1: "144", a2: "140", a3: "12", a4: "9", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "19 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "21.3", weight_kg_per_km: "707", resistance_MOhm_km: "50", a1: "177", a2: "172", a3: "12", a4: "9", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "21 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "21.4", weight_kg_per_km: "728", resistance_MOhm_km: "50", a1: "225", a2: "218", a3: "11", a4: "9", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "21 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "22.3", weight_kg_per_km: "768", resistance_MOhm_km: "50", a1: "278", a2: "270", a3: "11", a4: "9", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "24 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "22.4", weight_kg_per_km: "821", resistance_MOhm_km: "50", a1: "325", a2: "315", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "24 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "24.4", weight_kg_per_km: "866", resistance_MOhm_km: "50", a1: "373", a2: "362", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "30 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "24.6", weight_kg_per_km: "970", resistance_MOhm_km: "50", a1: "433", a2: "420", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "30 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "25.7", weight_kg_per_km: "1024", resistance_MOhm_km: "50", a1: "518", a2: "503", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "40 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "27.3", weight_kg_per_km: "1226", resistance_MOhm_km: "50", a1: "598", a2: "580", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "40 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.8", outer_diameter_mm: "28.5", weight_kg_per_km: "1294", resistance_MOhm_km: "50", a1: "695", a2: "674", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "52 x 1.5 re", diameter_mm: "0.8", insulation_mm: "1.9", outer_diameter_mm: "32", weight_kg_per_km: "1585", resistance_MOhm_km: "50", a1: "806", a2: "781", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "52 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "1.9", outer_diameter_mm: "33.4", weight_kg_per_km: "1673", resistance_MOhm_km: "50", a1: "930", a2: "901", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "61 x 1.5 re", diameter_mm: "0.8", insulation_mm: "2.0", outer_diameter_mm: "33", weight_kg_per_km: "1815", resistance_MOhm_km: "50", a1: "930", a2: "901", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" },
            { sq_mm: "61 x 1.5 rm", diameter_mm: "0.8", insulation_mm: "2.0", outer_diameter_mm: "34.6", weight_kg_per_km: "1916", resistance_MOhm_km: "50", a1: "930", a2: "901", a3: "11", a4: "8", short_circuit_current_kA: "0.17", length_m: "1000/D" }
        ]
    }
}
console.log(detailProduct)

export const DetailNYA = ({ data }) => {
  return (
    <section>
        <section>
            <LabelCard
                label="Copper Conductor PVC Insulated Building Wire"
                text="Cu/PVC 450/750 V (NYA)"
                image={CableOne}
                alt="Cu-PVC (NYA)"
                spesification="SNI 04-6629.3 : 2006"
            />
            <section className="flex justify-between mt-4 text-[11px]">
                <section className="w-[47%]">
                    <p><b>Conductor</b><br />Annealed plain copper round circular stranded or according to SNI IEC 60228</p>
                    <p className="mt-4"><b>Insulation</b><br />Extruded layer of Polyvinyl Chloride (PVC) complied with SNI 6629.1 ; SNI 04-6629.3</p>
                    <p className="mt-4">Colour:<br />- Yellow Strip Green or Light Blue or Black or Yellow or Red</p>
                    <img src={Colour} alt="colour" className="mt-3" />
                </section>
                <section className="w-[47%]">
                    <p><b>Applications:</b><br />Permanent Installation in conduit or exposed wiring in dry location.</p>
                    <p className="mt-4">Note:<br />- [re] round solid conductor<br />- [rm] circular stranded conductor</p>
                    <p className="mt-4">Packing:<br />- [C] Coil<br />- [D] Drum</p>
                </section>
            </section>
            <Table className="mt-12" id="zebra-table">
                <thead>
                    <Tr>
                        <Th rowSpan={2}>Conductor<br />Nominal<br />Cross-section<br />Area</Th>
                        <Th rowSpan={2}>Nominal<br />Thickness of<br />Insulation</Th>
                        <Th rowSpan={2}>Overall<br />Diameter<br />(approx)</Th>
                        <Th rowSpan={2}>Weight<br />of Cable<br />(approx)</Th>
                        <Th rowSpan={2}>Min. Insulation<br />DC. Resistance<br />at 20°C</Th>
                        <Th colSpan={2}>Current Carrying<br />Capacity at 30°C</Th>
                        <Th rowSpan={2}>Short Circuit<br />Current<br />Capacity at<br />1.0 Second</Th>
                        <Th rowSpan={2}>Standard<br />Delivery<br />Length</Th>
                    </Tr>
                    <Tr>
                        <Th>In Pipe</Th>
                        <Th>In Air</Th>
                    </Tr>
                    <tr>
                        <Th>sq.mm</Th>
                        <Th>mm</Th>
                        <Th>mm</Th>
                        <Th>kg/km</Th>
                        <Th>M.Ohm.</Th>
                        <Th>A</Th>
                        <Th>A</Th>
                        <Th>kA</Th>
                        <Th>mm</Th>
                    </tr>
                </thead>
                <tbody>
                    {data?.dataTableNYA?.NYA?.map((val, key) => {
                        const borderColor = key % 2 === 0 ? 'bg-white' : 'bg-soft-blue';
                        const borderClass = `border border-white text-center ${borderColor}`;

                        return(
                            <tr>
                                <td className={`${borderClass} px-4 py-2`}>{val?.sqmm}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.mm_nominal}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.mm_overall}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.kg}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.mohm}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.tipe_a}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.air_a}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.kA}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.mm_standard}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </section>

        <hr className="my-12 border-soft-blue" />

        <section>
            <LabelCard
                label="Single Core Flexible Copper Conductor PVC Insulated"
                text="Cu/PVC-f 450/750 V (NYAF)"
                image={CableTwo}
                alt="Cu-PVC (NYA)"
                spesification="SNI 04-6629.5 : 2006"
            />
            <section className="flex justify-between mt-4 text-[11px]">
                <section className="w-[47%]">
                    <p><b>Conductor</b><br />Annealed plain copper wires bunch stranded, according toSNI IEC 60228</p>
                    <p className="mt-4"><b>Insulation</b><br />Extruded layer of Polyvinyl Chloride (PVC) complied with SNI 6629.1 ; SNI 04-6629.5</p>
                    <p className="mt-4">Colour:<br />Yellow Strip Green or Light Blue or Black or Yellow or Red</p>
                    <img src={ColourTwo} alt="colour" className="mt-3" />
                </section>
                <section className="w-[47%]">
                    <p><b>Applications:</b><br />Used for fixed Installation inside appliances,switchgear and controlgear.</p>
                    <p className="mt-4">Note:<br />- [f] Flexible bunched wire</p>
                    <p className="mt-4">Packing:<br />- [C] Coil<br />- [D] Drum</p>
                </section>
            </section>
            <Table className="mt-12" id="zebra-table">
                <thead>
                    <Tr>
                        <Th colSpan={2}>Conductor</Th>
                        <Th rowSpan={2}>Nominal<br />Thickness<br />Insulation</Th>
                        <Th rowSpan={2}>Overall<br />Diameter<br />(approx)</Th>
                        <Th rowSpan={2}>Weight<br />of Cable<br />(approx)</Th>
                        <Th rowSpan={2}>Min. Insulation<br />DC. Resistance<br />at 20°C</Th>
                        <Th colSpan={2}>Current Carrying<br />Capacity at 30°C</Th>
                        <Th rowSpan={2}>Short Circuit<br />Current<br />Capacity at<br />1.0 Second</Th>
                        <Th rowSpan={2}>Standard<br />Delivery<br />Length</Th>
                    </Tr>
                    <Tr>
                        <Th>Nominal<br />crosssection<br />area</Th>
                        <Th>No. of wire<br />and<br />Diameter</Th>
                        <Th>In Pipe</Th>
                        <Th>In Air</Th>
                    </Tr>
                    <tr>
                        <Th>sq.mm</Th>
                        <Th>mm</Th>
                        <Th>mm</Th>
                        <Th>mm</Th>
                        <Th>mm</Th>
                        <Th>M.Ohm.km</Th>
                        <Th>A</Th>
                        <Th>A</Th>
                        <Th>M.Ohm.</Th>
                        <Th>mm</Th>
                    </tr>
                </thead>
                <tbody>
                    {data?.dataTableNYA?.NYAF?.map((val, key) => {
                        const borderColor = key % 2 === 0 ? 'bg-white' : 'bg-soft-blue';
                        const borderClass = `border border-white text-center ${borderColor}`;

                        return(
                            <tr>
                                <td className={`${borderClass} px-4 py-2`}>{val?.sqmm}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.mm_wire}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.mm_nominal}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.mm_overall}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.kg}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.mohmkm}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.pipe_a}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.air_a}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.kA}</td>
                                <td className={`${borderClass} px-4 py-2`}>{val?.mm_standard}</td>
                            </tr>
                        )
                    })}
                </tbody>
            </Table>
        </section>
    </section>
  )
}