import React from 'react'
import { Button, InfoSection, TextLabel } from '../../components'
import { FaLocationDot } from "react-icons/fa6";
import { data } from '../../datas'
import { FaPhoneAlt, FaClock } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';
import { HiOfficeBuilding } from "react-icons/hi";
import { IoPhonePortrait } from "react-icons/io5";
import { copyTextToClipboard } from "../../utilities";

export const Footer = () => {
  return (
    <section className='bg-soft-blue mx-[-16px] lg:mx-[-31px]'>
        <section className='mt-12 lg:flex lg:justify-between pt-10 pl-[15px] lg:pl-[30px]'>
            <section className='text-left'>
                <TextLabel text={data?.personal?.name} className="text-left !text-[25px] lg:!text-[30px]" />
                <p className='flex items-baseline font-antonio text-dark-blue my-2'><FaLocationDot className='mr-2' /><b>OFFICE:</b></p>
                <p className='flex items-baseline font-antonio opacity-[60%] text-[15px] lg:text-[18px]'>{data?.personal?.address}</p>
                <p className='flex items-baseline font-antonio opacity-[60%] text-[15px] lg:text-[18px]'>{data?.personal?.street}</p>
                <section className='flex p-3 !pl-0 gap-2 items-center my-2'>
                    <Button text={<FaPhoneAlt />} onClick={() => copyTextToClipboard(data?.personal?.detail_phone?.[0]?.phone)} />
                    <Button text={<MdEmail />} onClick={() => copyTextToClipboard(data?.personal?.email)} />
                    <Button className="!w-[190px] uppercase !rounded-[20px] font-lexend" text={`Hotline: ${data?.personal?.hp}`} onClick={() => copyTextToClipboard(data?.personal?.hp)} />
                </section>
            </section>
            <hr className='border-dark-blue border-1 my-4 mr-[30px]' />
            <section>
                <TextLabel text="We Are Ready To Serve You" className="!text-[25px] lg:!text-[29px] text-left" />
                <section className='lg:flex lg:justify-between'>
                    <section>
                        <InfoSection icon={<FaClock className='mr-2' />} label="OPEN:" text={data?.personal?.open} />
                        <InfoSection icon={<FaPhoneAlt className='mr-2' />} label="PHONE:" text={data?.personal?.detail_phone?.[0]?.phone} additional={data?.personal?.detail_phone?.[1]?.phone} />
                    </section>
                    <section className='ml-0 lg:ml-10'>
                        <InfoSection icon={<HiOfficeBuilding className='mr-2' />} label="FAX:" text={data?.personal?.fax} />
                        <InfoSection icon={<IoPhonePortrait className='mr-2' />} label="HP:" text={data?.personal?.hp} />
                        <InfoSection icon={<MdEmail className='mr-2' />} label="EMAIL:" text={data?.personal?.email} />
                    </section>
                </section>
            </section>
            <section className='hidden lg:inline'>
                <img src={data?.personal?.logo} alt={data?.personal?.name} className='h-[348px] w-[348px] mt-[-45px]' />
            </section>
        </section>
        <section className='px-[30px] mt-0 lg:mt-[-50px] pb-5'>
            <hr className='border-dark-blue border-1 my-4' />
            <section className='lg:flex lg:justify-between items-center'>
                <p className='flex items-baseline font-antonio text-dark-blue text-[10px] lg:text-[14px] mb-2 lg:mb-0 justify-center'>{data?.personal?.footer}</p>
                <section className='flex justify-center'><img src={data?.assets?.bank} alt={data?.personal?.name} /></section>
            </section>
        </section>
    </section>
  )
}