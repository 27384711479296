import React from 'react'
import { Button, Card, Input, TextArea, TextLabel } from '../../components'
// import ReCAPTCHA from "react-google-recaptcha";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Formik, Form } from "formik";
import * as Yup from "yup";
import 'leaflet/dist/leaflet.css';

export const Contact = () => {
  const position = [-6.286775164683676, 106.92324562441314];
  // const onChange = (value) => { console.log("Captcha value:", value) }

  const initialValues = { name: "", email: "", message: "" };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required!"),
    email: Yup.string().required("Email is required!"),
    message: Yup.string().required("Message is required!")
  });

  const onSubmit = (values) => {
    const { name, email, message } = values;
    const phoneNumber = '0811996233';
    const messageText = `Name: ${name}\nEmail: ${email}\nMessage: ${message}`;
    
    const encodedMessage = encodeURIComponent(messageText);
    const url = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
  
    window.location.href = url;
  };

  return (
    <section className='mt-10'>
      <Card className="lg:flex pb-2 pt-8 lg:py-8 lg:pr-8 px-2 lg:px-6">
        <section className='text-left hidden lg:inline w-[53%]'>
          <MapContainer center={position} zoom={13} className='lg:h-[395px] rounded-[40px] mb-4'>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
            <Marker position={position}>
              <Popup>A pretty CSS3 popup. <br /> Easily customizable.</Popup>
            </Marker>
          </MapContainer>
          <section className='w-[267px]'><a href="https://maps.app.goo.gl/ou9WCpN1BqgNPhza7" target='blank' className='font-lexend text-[#417ABD]'><b>*Click Here To Open Google Map</b></a></section>
        </section>
        <section className='text-left lg:mt-0 mb-5 lg:ml-10 w-[100%]'>
          <TextLabel className="text-center lg:text-left mb-5 lg:mb-10 lg:mb-0" text="Contact Us" />

          <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
            {({ values, errors, touched, setValues }) => (
              <Form>
                <section className='lg:flex lg:justify-between mb-3 w-full'>
                  <section className='mb-3 lg:w-full lg:mr-2'>
                    <Input 
                      type="text" 
                      label="Name*" 
                      placeholder="Input Name..." 
                      className="w-full" 
                      value={values.name}
                      onChange={(e) => setValues({ ...values, name: e.target.value })}
                      error={Boolean(errors.name && touched.name)}
                      errorText={errors.name && touched.name ? errors.name : ""}
                    />
                  </section>
                  <section className='lg:w-full lg:ml-2'>
                    <Input 
                      type="email" 
                      label="Email*" 
                      placeholder="Input Email..." 
                      className="w-full" 
                      value={values.email}
                      onChange={(e) => setValues({ ...values, email: e.target.value })}
                      error={Boolean(errors.email && touched.email)}
                      errorText={errors.email && touched.email ? errors.email : ""}
                    />
                  </section>
                </section>
                <TextArea 
                  type="text" 
                  label="Message*" 
                  placeholder="Input Message..." 
                  className="w-full" 
                  value={values.message}
                  onChange={(e) => setValues({ ...values, message: e.target.value })}
                  error={Boolean(errors.message && touched.message)}
                  errorText={errors.message && touched.message ? errors.message : ""}
                  rows={4} 
                />
                <div className='lg:flex lg:justify-end mb-3'>
                  {/* <section className='overflow-x-auto flex justify-center'>
                    <ReCAPTCHA sitekey="6Le4higqAAAAAPqZPdzw3q76AAhnqUtr8GK_XI-t" onChange={onChange} className='mt-3' />
                  </section> */}
                  <Button 
                    type="submit"
                    text="Send Message" 
                    className="w-full lg:!w-[257px] !h-[50px] lg:!h-[60px] !rounded-[16px] lg:!rounded-[15px] hover:!bg-[#1A60B2] hover:!text-white uppercase mt-3" 
                  />
                </div>
              </Form>
            )}
          </Formik>
        </section>
        <section className='text-center mt-10 lg:hidden'>
          <section className='flex justify-start mb-2'><a href="https://maps.app.goo.gl/ou9WCpN1BqgNPhza7" target='blank' className='font-lexend text-[10px] text-[#417ABD]'><b>*Click Here To Open Google Map</b></a></section>
          <MapContainer center={position} zoom={13} className='!h-[384px] !w-full rounded-[15px] mb-4'>
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors' />
            <Marker position={position}>
              <Popup>A pretty CSS3 popup. <br /> Easily customizable.</Popup>
            </Marker>
          </MapContainer>
        </section>
      </Card>
    </section>
  )
}