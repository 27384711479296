export const TextArea = ({ type, error, label, className, placeholder, rows, errorText, onChange, value }) => {
  return (
    <label className={`form-control`}>
      {label && (
        <h1 className="font-lexend mb-1">
          <span className={`text-dark-blue ${error && "text-error"}`}>{label}</span>
        </h1>
      )}

      <textarea
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        className={`input input-bordered border-dark-blue py-3 px-5 rounded-[12px] lg:rounded-[15px] font-lexend text-dark-blue ${className} ${error && "!border-[#FF0000] border-[1px]"}`}
        rows={rows}
      />

      {error && <p className="!mt-[-6px] text-[#FF0000]"><small>{errorText}</small></p>}
    </label>
  )
}