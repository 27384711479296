import React, { useState } from 'react'
import { Button, Card, TextLabel } from '../../components'
import { data } from '../../datas'
import { copyTextToClipboard } from "../../utilities"
import { HiOutlineAdjustments } from "react-icons/hi";
import { FaTimes } from "react-icons/fa";

export const Product = () => {
  const [isSelected, setIsSelected] = useState("Cu-PVC (NYA)")
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section>
        <section className='inline lg:hidden flex justify-between items-end mt-12'>
            <section>
                <TextLabel className="!text-left" text="Product" />
                <h1 className='font-lexend !mt-[-10px] text-[11px]'>Category Product</h1>
            </section>
            <section className='flex gap-1'>
                <Button className="h-[28px] w-[28px]" text={<HiOutlineAdjustments className='h-[20px] w-[20px]' />} onClick={() => setIsOpen(!isOpen)} />
            </section>
        </section>
        <section className='flex mt-3 lg:mt-10'>
            <section className='hidden lg:inline w-[327px] text-left mr-10'>
                <TextLabel text="Product" />
                <p className='font-lexend mt-[-10px]'>Category Product</p>
                <section className='mt-6'>
                    {data?.personal?.product?.map(val => (
                        <Button 
                            text={val?.label} 
                            className={`!w-full !h-[60px] !rounded-[32px] !text-left !justify-start !px-6 mb-3 ${val?.label === isSelected ? "" : "!bg-white"} hover:!bg-dark-blue ${val?.label === isSelected ? "" : "!text-dark-blue"} hover:!text-white border-[1px] border-soft-blue !text-[12px]`} 
                            onClick={() => setIsSelected(val?.label)}
                        />
                    ))}
                    <div className='pt-3 pb-5'><hr className='border-dark-blue border-1 mx-7' /></div>
                    <Button 
                        text={`HP: ${data?.personal?.hp}`} 
                        className="!w-full !h-[60px] !rounded-[15px] !text-left !justify-start !px-6 mb-1 !text-[12px]" 
                        onClick={() => copyTextToClipboard(data?.personal?.hp)} 
                    />
                    <Button 
                        text={`PHONE (1): ${data?.personal?.detail_phone?.[0]?.phone}`} 
                        className="!w-full !h-[60px] !rounded-[15px] !text-left !justify-start !px-6 mb-1 !text-[12px]" 
                        onClick={() => copyTextToClipboard(data?.personal?.detail_phone?.[0]?.phone)} 
                    />
                    <Button 
                        text={`PHONE (2): ${data?.personal?.detail_phone?.[1]?.phone}`} 
                        className="!w-full !h-[60px] !rounded-[15px] !text-left !justify-start !px-6 mb-1 !text-[12px]" 
                        onClick={() => copyTextToClipboard(data?.personal?.detail_phone?.[1]?.phone)} 
                    />
                    <Button 
                        text={`FAX: ${data?.personal?.fax}`} 
                        className="!w-full !h-[60px] !rounded-[15px] !text-left !justify-start !px-6 mb-1 !text-[12px]" 
                        onClick={() => copyTextToClipboard(data?.personal?.fax)} 
                    />
                    <Button 
                        text={`EMAIL: ${data?.personal?.email}`} 
                        className="!w-full !h-[60px] !rounded-[15px] !text-left !justify-start !px-6 mb-1 !text-[12px]" 
                        onClick={() => copyTextToClipboard(data?.personal?.email)} 
                    />
                </section>
            </section>
            <section className='w-full text-left'>
                {data?.personal?.product?.map(val => val)?.filter(items => items?.label === isSelected).map(product => (
                    <Card className="!bg-white border-[1px] border-soft-blue p-12 !rounded-[32px] !font-lexend lg:!min-h-[1095px]">{product?.detail()}</Card>
                ))}
            </section>
        </section>

        {isOpen && (
            <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-[10000]">
                <div className="bg-white rounded-lg shadow-lg w-96 p-6 rounded-[26px]">
                    <section className='flex justify-between items-center'>
                        <section className='flex items-center gap-2'>
                            <Button className="h-[28px] w-[28px]" text={<HiOutlineAdjustments className='h-[20px] w-[20px]' />} />
                            <TextLabel className="!text-left !text-[20px]" text="Product" />
                        </section>
                        <Button className="!h-[20px] !w-[20px]" text={<FaTimes className='h-[10px] w-[10px]' />} onClick={() => setIsOpen(!isOpen)} />
                    </section>
                    <section className='mt-6'>
                        {data?.personal?.product?.map(val => (
                            <Button 
                                text={val?.label} 
                                className={`!w-full !h-[40px] !rounded-[32px] !text-left !justify-start !px-6 mb-3 ${val?.label === isSelected ? "" : "!bg-white"} hover:!bg-dark-blue ${val?.label === isSelected ? "" : "!text-dark-blue"} hover:!text-white border-[1px] border-soft-blue !text-[11px]`} 
                                onClick={() => {
                                    setIsSelected(val?.label)
                                    setIsOpen(!isOpen);
                                }}
                            />
                        ))}
                    </section>
                </div>
            </div>
        )}
    </section>
  )
}