export const Input = ({ type, error, label, className, placeholder, errorText, onChange, value, key }) => {
  return (
    <label className={`form-control`}>
      {label && (
        <h1 className="font-lexend mb-1">
          <span className={`text-dark-blue ${error ? "text-error" : ""}`}>{label}</span>
        </h1>
      )}

      <input
        key={key}
        type={type}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className={`input input-bordered border-dark-blue py-3 px-5 rounded-[12px] lg:rounded-[15px] font-lexend text-dark-blue ${className} ${error && "!border-[#FF0000] border-[1px]"}`}
      />

      {error && (
        <label className="label">
          {error && <p className="text-[#FF0000]"><small>{errorText}</small></p>}
        </label>
      )}
    </label>
  )
}